module controllers {
    export module applicationcore {
        export class tariffLookupCtrl {

            static $inject = ['$scope', '$rootScope', '$q', '$uibModalInstance', '$uibModal', 'entityService',  'generalService',
                'bsLoadingOverlayService', 'countryService', 'tariffCountryService', 'dutyTypeService', 'classificationValueService', 'hsCodeService', 'tariffCodeType', 'countryId', 'allowAllTypes', 'measureCode', 'wcoTradeToolsService','entityId','codeTypeClassificationValueId'];
            searchAccordian: boolean = true;

            dutyTypeList: ng.resource.IResourceArray<interfaces.tariff.IDutyTypeDropDown>;
            selectedDutyType: interfaces.tariff.IDutyTypeDropDown;
            selectedCountry: interfaces.applicationcore.IDropdownModel;
            selectedCodeType: interfaces.applicationcore.IDropdownModel;
            simulationDate: moment.Moment = moment.utc();
            nationalMeasureCode: string;
            description: string;
            exclusions: boolean = true;

            messages: interfaces.applicationcore.IMessage[];

            apiTariffCodes: uiGrid.IGridApi;

            showGrid: boolean = false;
            isLoading: boolean = false;
            wcoTradeToolsEnabled: boolean = false;

            allowManualTariff: boolean = true;

            constructor(private $scope: ng.IScope, private $rootScope: interfaces.applicationcore.IRootScope, private $q: ng.IQService,
                public $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, private $uibModal: ng.ui.bootstrap.IModalService, private entityService: interfaces.applicationcore.IEntityService, public generalService: interfaces.applicationcore.IGeneralService,
                private bsLoadingOverlayService,
                private countryService: interfaces.applicationcore.ICountryService,
                private tariffCountryService: interfaces.tariff.ITariffCountryService,
                private dutyTypeService: interfaces.tariff.IDutyTypeService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService, private hsCodeService: interfaces.tariff.IHsCodeService,
                private tariffCodeType: Enum.EnumTariffCodeType, private countryId: number, private allowAllTypes: boolean, private measureCode: string,
                private wcoTradeToolsService: interfaces.tariff.IWCOTradeToolsService,
                private entityId:number,
                private codeTypeClassificationValueId:number) {                
                   
                this.nationalMeasureCode = measureCode;

                var promises: ng.IPromise<any>[] = [];


                if (!countryId) {
                    var countryPromise =  entityService.getEntityCountry().get({ entityId: 0 }, (result: interfaces.applicationcore.ICountry) => {
                        this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                            Id: result.Id,
                            Code: result.Code,
                            Description: result.Description,
                            Display: result.Code + " - " + result.Description,
                            Selected: true
                        };
                        this.getDefaultDutyType(this.selectedCountry.Id);
                        this.getTariffCountrySetting();
                        if (this.codeTypeClassificationValueId){
                            this.lodeCodeTypes();
                        }
                    }).$promise;

                    promises.push(countryPromise);
                } else {
                    var countryPromise =  countryService.get().get({ cntId: countryId }, (result: interfaces.applicationcore.ICountry) => {
                        this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                            Id: result.Id,
                            Code: result.Code,
                            Description: result.Description,
                            Display: result.Code + " - " + result.Description,
                            Selected: true
                        };
                        this.getDefaultDutyType(this.selectedCountry.Id);
                        this.getTariffCountrySetting();
                        if (this.codeTypeClassificationValueId){
                            this.lodeCodeTypes();
                        }
                      //  this.get
                    }).$promise;

                    promises.push(countryPromise);
                }

                promises.push(this.verifyWCOTradeToolsEnabled());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.lookup'
                }, () => {
                    return $q.all(promises);
                });
            }

            verifyWCOTradeToolsEnabled()
            {
                return this.wcoTradeToolsService.VerifyWCOTradeToolsEnabled().get({ }, (result: interfaces.tariff.VerifyTradeTools) => {
                  
                    this.wcoTradeToolsEnabled = result.isWCOTradeToolsEnabled;
                }).$promise;     
            }

            getTariffCountrySetting()
            {
                return this.tariffCountryService.getTariffCountrySetting(this.entityId,this.selectedCountry.Id).get( (result: interfaces.tariff.ITariffCountrySetting) => {
                        if (result){
                            this.allowManualTariff = result.allowManualTariff;
                        }
                }).$promise;

            }



            public gvwTariffCodes: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnMenus: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                onRegisterApi: (gridApi) => { this.apiTariffCodes = gridApi; },
                columnDefs: [{
                    name: "SELECT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton">
                        <center>
                            <button ng-if="row.entity.IsSelectable" type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.tariffLookupCtrl.selectItem(row.entity)">
                                <span class="fa fa-check"></span>
                            </button>
                        </center>
                    </div>
                    `,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 50
                },  {
                    name: 'VIEW',
                    displayName: 'View',
                    field: '',
                    enableSorting: false,
                    enableCellEdit: false,
                    cellTemplate: `<div ng-if="row.entity.DutyTypeCode">
                                        <gts-tariff-detail  national-measure-code="row.entity.NationalMeasureCode" 
                                                            duty-type-code="row.entity.DutyTypeCode"
                                                            duty-type-id="row.entity.CodeTypeClassificationValueId"
                                                            country-id="row.entity.CountryId"
                                                            simulation-date="grid.appScope.tariffLookupCtrl.simulationDate">
                                        </gts-tariff-detail>
                                </div>`,
                    width: 50
                },  {
                    name: "CODE",
                    displayName: "Code",
                    field: "FormattedCode",
                    enableFiltering: false,
                    width: 150
                }, {
                    name: "ICON",
                    displayName: "P&R",
                    field: "PR",
                    cellTemplate: `
                    <div>
                        <center>
                            <div ng-if="row.entity.PR === 0">
                                <span class="fa fa-check" style="color: #bcd856;"></span>
                            </div>
                            <div ng-if="row.entity.PR === 1">
                                <span class="fa fa-warning" style="color: #f57f20;"></span>
                            </div>
                            <div ng-if="row.entity.PR === 2">
                                <span class="fa fa-warning" style="color: #cb2330;"></span>
                            </div>
                        </center>
                    </div>`,
                    enableFiltering: false,
                    width: 45
                }, {
                    name: "DESCRIPTION",
                    displayName: "Description",
                    field: "Description",
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-click="grid.appScope.tariffLookupCtrl.showDescription(row.entity)" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{row.entity.Description}}</div>`,
                    enableFiltering: false,
                    width: 489
                }, {
                    name: "DUTY",
                    displayName: "Duty",
                    field: "DutyRate",
                    enableFiltering: false,
                    width: 150
                }, {
                    name: "DutyTypeCode",
                    displayName: "Duty Type",
                    field: "DutyTypeCode",
                    enableFiltering: false,
                    width: 75
                }, {
                    name: "StatisticalUnitCode",
                    displayName: "UoM",
                    field: "StatisticalUnitCode",
                    enableFiltering: false,
                    width: 45
                }, {
                    name: "Notes",
                    displayName: "Legal Notes",
                    field: "Notes",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <center>
                            <button ng-if="row.entity.Notes" type="button" class="btn btn-info btn-sm" ng-click="grid.appScope.tariffLookupCtrl.displayNotes(grid.getCellValue(row, col))">
                                <span class="fa fa-sticky-note"></span>
                            </button>
                        </center>
                    </div>`,
                    width: 51,
                    headerTooltip: "Legal Notes",
                    enableColumnResizing: true
                }
                ,   {
                    name: "WCONotes",
                    displayName: "Explanatory Notes",
                    field: "Notes",
                    enableFiltering: false,
                    enableColumnResizing: true,
                    cellTemplate: `
                    <div class="GridButton" ng-if="row.entity.NationalMeasureCode.length <= 4 && row.entity.CodeTypeClassificationValueId === 163">
                        <center>
                                <wco-trade-tool-notes  
                                is-section = "row.entity.IsSection"
                                section="row.entity.Section"
                                hs-code="row.entity.NationalMeasureCode"
                                year="2022"
                                notes-type="1"
                                wco-enabled="grid.appScope.tariffLookupCtrl.wcoTradeToolsEnabled"
                            >
                            </wco-trade-tool-notes>
                        </center>
                    </div>`,
                    width: 51,
                    enableSorting: false,
                    headerTooltip: "Explanatory Notes"
                },
                {
                    name: "WCOClassNotes",
                    displayName: "Classification Opinion",
                    field: "Notes",
                    enableFiltering: false,
                    enableColumnResizing: true,
                    cellTemplate: `
                    <div class="GridButton" ng-if="row.entity.NationalMeasureCode.length === 6 && row.entity.CodeTypeClassificationValueId === 163">
                        <center>
                                <wco-trade-tool-notes  
                                is-section = "row.entity.IsSection"
                                section="row.entity.Section"
                                hs-code="row.entity.NationalMeasureCode"
                                year="2022"
                                notes-type="3"
                                wco-enabled="grid.appScope.tariffLookupCtrl.wcoTradeToolsEnabled"
                            >
                            </wco-trade-tool-notes>
                        </center>
                    </div>`,
                    width: 51,
                    enableSorting: false,
                    headerTooltip: "Classification Opinion"
                }]
            };   

            search_click() {
                this.searchAccordian = false;
                this.isLoading = true;
                this.showGrid = true;
                this.hsCodeService.getSimplifiedExpertSearchResult().query({
                    type: this.selectedDutyType.CodeTypeClassificationValueId,
                    countryId: this.selectedCountry.Id,
                    simulationDate: this.simulationDate.format("YYYYMMDDTHH:mm"),
                    code: this.nationalMeasureCode,
                    description: this.description,
                    exclusions: this.exclusions,
                    dutyTypeId: this.selectedDutyType.Id
                }, (resultList) => {
                    this.gvwTariffCodes.data = resultList;
                    this.isLoading = false;
                    }, (errorResponse) => {
                        this.isLoading = false;
                        if (errorResponse.data.Messages) {
                            this.messages = [];
                        }

                        angular.forEach(errorResponse.data.Messages, (o) => {
                            angular.forEach(o.MessageItems, (n) => {
                                this.messages.push(n);
                            });
                        });
                });
            }

            getDefaultDutyType(countryId: number) {
                return this.dutyTypeService.getDefaultForCountryDropDownByType(countryId, this.tariffCodeType).get((result: interfaces.tariff.IDutyTypeDropDown) => {
                    this.selectedDutyType = <interfaces.tariff.IDutyTypeDropDown>{
                        Id: result.Id,
                        Code: result.Code,
                        Description: result.Description,
                        Display: result.Code + " - " + result.Description,
                        CodeTypeClassificationValueId: result.CodeTypeClassificationValueId,
                        Selected: true
                    };
                }).$promise;
            }

            simulationCountryChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.selectedCountry = model;
                this.selectedDutyType = null;
                this.dutyTypeList = null;

                
                this.getDefaultDutyType(this.selectedCountry.Id);
                
                this.loadDutyTypes(this.selectedCountry.Id);

                this.getTariffCountrySetting();
            }

            loadDutyTypes(countryId: number) {
                if (this.allowAllTypes) {
                    return this.dutyTypeService.getForTariffSearchDropDown(countryId).query((result: ng.resource.IResourceArray<interfaces.tariff.IDutyTypeDropDown>) => {
                        this.dutyTypeList = result;
                    }
                    ).$promise;
                }
                else {
                    return this.dutyTypeService.getForTariffSearchDropDownByType(countryId, this.tariffCodeType).query((result: ng.resource.IResourceArray<interfaces.tariff.IDutyTypeDropDown>) => {
                        this.dutyTypeList = result;
                    }
                    ).$promise;
                }
            }

            loadDutyTypeData() {
                return this.loadDutyTypes(this.selectedCountry.Id);
            }

            loadCountries() {
                return this.tariffCountryService.getDropdownListByTransactionEntity(this.entityId).query().$promise;
            }

            lodeCodeTypes() {
                return this.classificationValueService
                  .GetClassificationValueDropdownByClassification()
                  .query(
                    {
                      classificationId: Enum.ClassificationEnum.TariffCodeType,
                    },
                    (resultList: Array<interfaces.applicationcore.IDropdownModel>) => {
                        if (this.codeTypeClassificationValueId){
                            this.selectedCodeType =  _.find(resultList, (o) => { return o.Id === this.codeTypeClassificationValueId});
                        }
                    }
                  ).$promise;
              }


            cancel() {
                this.$uibModalInstance.dismiss('cancel');
            }

            displayNotes(notes: string) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Notes</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Notes"></p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Notes = notes;

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            showDescription(selectedItem: interfaces.tariff.INationalMeasureCodeLookup) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Code: {{Code}}</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Description"></p>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Description = selectedItem.Description;
                        $scope.Code = selectedItem.NationalMeasureCode;
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            selectItem(selectedItem: interfaces.tariff.INationalMeasureCodeLookup) {
                selectedItem.SimulationDate = this.simulationDate;
                this.$uibModalInstance.close(selectedItem);
            }

            updateItem(){
                
                var isValid : boolean = false;

                if (this.nationalMeasureCode)
                {
                   var _nationalMeasureCodeValidation = this.nationalMeasureCode.replace('.', '');
                   if (_nationalMeasureCodeValidation.length<6){
                       var errorMessage = <interfaces.applicationcore.IMessage>{
                            ID: "0",
                            MessageType: 1,
                            LocaleDescription:"National Measure Code must be 6 digits or more.",
                            TypeEnum: 1,
                            MessageItems: <interfaces.applicationcore.IMessageItem[]>[{
                                LocaleDescription: "National Measure Code must be 6 digits or more.",
                                TypeEnum: 1
                            }]

                       };
                       this.messages = [];
                       this.messages.push(errorMessage);
                       
                       isValid = false;
                   }
                   else
                   {
                       isValid = true;
                   }
                }

                if (isValid)
                {
                    var selectedItem = <interfaces.tariff.INationalMeasureCodeLookup>{
                        NationalMeasureCode: this.nationalMeasureCode,
                        CountryId: this.selectedCountry.Id,
                        SimulationDate: this.simulationDate,
                        CodeTypeClassificationValueId: this.selectedCodeType.Id
                    }

                    
                        this.$uibModalInstance.close(selectedItem);
                }
            }
        };

        angular.module("app").controller("tariffLookupCtrl", controllers.applicationcore.tariffLookupCtrl);
    }
}